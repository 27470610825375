


































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { BeatStore } from '#/store/beat'
import { fetchVaultBeats } from '#/api/beat'
import Pagination from '#/components/Pagination.vue'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { updateProducer } from '#/api/profile'
import { UserStore } from '#/store/user'
import AlertBoxSubscription from '#/components/AlertBoxSubscription.vue'

@Component({ components: { VLoadSpinner, Pagination, AlertBoxSubscription } })
export default class BeatsPrivateVaultPage extends Vue {
    beats: IBeat[] = []
    isLoading: boolean = true

    @BeatStore.State('selectedBeats') selectedBeats: IBeat[]
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
    @UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
    @UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean

    get passwordButtonText() {
        return this.authenticatedUser?.private_beats_password ? 'Set New Password' : 'Set Password'
    }

    async _fetchAllPrivateBeats() {
        this.beats = await fetchVaultBeats()
        this.isLoading = false
    }

    async saveVaultPassword() {
        try {
            await updateProducer({
                id: this.authenticatedUser.id,
                private_beats_password: this.authenticatedUser.private_beats_password
            })
            // show success message
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    openSetPasswordModal() {
        this.SET_ACTIVE_MODAL({ modal: ModalType.SetVaultPassword, callback: () => this.saveVaultPassword() })
    }

    created() {
        this._fetchAllPrivateBeats()
    }
}
