























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { NUM_OF_BEATS_PER_PAGE } from '#/store/beat'
import { NUM_OF_ORDERS_PER_PAGE  } from '#/store/orders'
import { IS_ADMIN } from '~/const/environment'

@Component
export default class Pagination extends Vue {
	@Prop({ type: Number }) pageIndex: number
	@Prop({ type: Number }) numberOfPages: number

	showingAllItems = false
	IS_ADMIN = IS_ADMIN

	// yes IF for beats in producer dashboard OR in admin new uploadas page
	get isForBeats() {
		return ['BeatsCatalog', 'NewUploads'].includes(this.$route.name)
	}

	get NUMBER_OF_PAGES_PER_SET() {
		// numberOfPages we see in each section
		return document.body.clientWidth >= 800 ? 8 : 2
	}
	get NUMBER_OF_PAGES_PER_SHIFT() {
		// numberOfPages we change each time we click next/prev
		return document.body.clientWidth >= 800 ? 5 : 2
	}

	get itemsPerPage() {
		if (this.isForBeats) return NUM_OF_BEATS_PER_PAGE
		return NUM_OF_ORDERS_PER_PAGE
	}
	get pagesToSee() {
		let firstPage
		switch (this.pageIndex) {
			case 1:
				firstPage = 1
				break
			case 2:
			case 3:
			case 4:
				firstPage = this.pageIndex - (this.pageIndex - 1)
				break
			default:
				firstPage = this.pageIndex - 4
				break
		}

		// last page
		const lastPage = Math.min(this.numberOfPages, firstPage + this.NUMBER_OF_PAGES_PER_SET)
		const array = []
		let j = 0
		for (let i = firstPage; i <= lastPage; i++) {
			array[j] = i
			j++
		}
		return array
	}
	get canGoToPreviousSet() {
		// we are not on the first page
		return this.pageIndex > 1
	}
	get canGoToNextSet() {
		// there is at least ONE more page to the right
		return this.pageIndex < this.numberOfPages - 1
	}
	get shouldShowPrevDots() {
		// if there are enought numberOfPages on the left
		return this.pageIndex - this.NUMBER_OF_PAGES_PER_SET > 0
	}
	get shouldShowNextDots() {
		// if there are enough numberOfPages on the right
		return this.pageIndex < this.numberOfPages - this.NUMBER_OF_PAGES_PER_SET
	}

	scrollToTop(scrollDuration: number) {
		const cosParameter = window.scrollY / 2
		let scrollCount = 0
		let oldTimestamp = performance.now()

		function step(newTimestamp: number) {
			scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
			if (scrollCount >= Math.PI) window.scrollTo(0, 0)
			if (window.scrollY === 0) return
			window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
			oldTimestamp = newTimestamp
			window.requestAnimationFrame(step)
		}

		window.requestAnimationFrame(step)
	}

	changePage(offset: number, page: number) {
		this.scrollToTop(200)
		setTimeout(() => {
			this.$emit('changePage', offset, page)
		}, 200)
	}

	fetchAllItems() {
		this.showingAllItems = true
		this.scrollToTop(200)
		this.$emit('fetchAll')
	}
	previousSet() {
		const currentPage = this.pageIndex
		const prevSetPage = Math.max(1, currentPage - this.NUMBER_OF_PAGES_PER_SHIFT)
		// offset of page 1 is 0, because index starts from 1
		this.changePage((prevSetPage - 1) * this.itemsPerPage, prevSetPage)
	}
	nextSet() {
		const currentPage = this.pageIndex
		const nextSetPage = Math.min(this.numberOfPages, currentPage + this.NUMBER_OF_PAGES_PER_SHIFT)
		// offset of page 1 is 0, because index starts from 1
		this.changePage((nextSetPage - 1) * this.itemsPerPage, nextSetPage)
	}
}
